import ModalVideo from 'modal-video';

class NavigationHandler {
  constructor(navSelector, firstSectionSelector) {
    this.navIsSticky = false;
    this.nav$ = $(navSelector);
    this.window$ = $(window);
    this.firstSectionHeight = $(firstSectionSelector).height();
  }

  init() {
    if (this.nav$.hasClass('navigation_static')) {
      return;
    }
    this.handleMenuState($(window).scrollTop());
    this.window$.scroll(({ currentTarget }) => {
      this.handleMenuState(currentTarget.pageYOffset);
    })
    this.nav$.on('animationend', ({ originalEvent }) => {
      if(originalEvent.animationName === 'menu-slide-out') {
        this.nav$.removeClass('navigation_sticky');
        this.nav$.removeClass('navigation_fade');
      }
    })
  }

  handleMenuState(currentScroll) {
    if(!this.nav$ || !this.firstSectionHeight) {
      return;
    }

    if (currentScroll > this.firstSectionHeight) {
      !this.navIsSticky && this.nav$.addClass('navigation_sticky');
      this.navIsSticky = true;
    } else {
      this.navIsSticky && this.nav$.addClass('navigation_fade');
      this.navIsSticky = false;
  }
  }
}

$(document).ready(function() {
  /**
   * Handle navigation menu state (top or sticky)
   */
  const navHandler = new NavigationHandler('#navigation', '#main-image-block');
  navHandler.init();

  /**
   * Init modal for youtube video
   */
  new ModalVideo('.main-button__video');
  new ModalVideo('.side-part__media-block-clickable');
  new ModalVideo('.reviews__gallery_video');

  prepareFloatGallery();
  /**
   * Animate main image block
   */
  $('#main-image-block').on('mousemove', (e) => {
    const x = e.clientX || 1;
    const width = $(window).width();
    const p = (x / width * -2) + 2;
    $('#main-image-block .main-image-block__mirror-line').css('background-position-x', `${50 + p * 2}%`);
    $('#main-image-block .main-image-block__bg').css('transform', `translateX(-${52 - p}%)`);
    $('#main-image-block .main-video-bg').css('transform', `translateX(-${52 - p}%)`);
    $('#main-image-block .main-image-block__mirror-line .main-video-bg').css('transform', `translateX(-${52 - p *2}%)`);
  })

  /**
   * Init owl for staff section
   */
  const staffOwl = $('.owl-carousel-staff');
  staffOwl.owlCarousel({
    items : 1,
    dots: true,
    margin: 40,
    nav: false,
    animateIn: 'staffAnimate',
  })

  const servisesSlide = $(".modal-servises__slide .owl-carousel");
  servisesSlide.each(function() {
    if ($(this).find('img').length > 1) {
      $(this).owlCarousel({
        items : 1,
        dots: false,
        nav: true,
        loop: true
      });
    }
  })


  const coupleSlide = $(".couple .owl-carousel");
  coupleSlide.owlCarousel({
    autoWidth:true,
    dots: false,
    nav: false,
  });



  function weddingSlideF() {
    const weddingSlide = $(".wedding-advantages.owl-carousel");
    if ( $(window).width() < 960 ) {
      weddingSlide.owlCarousel({
        items : 5,
        responsive : {
          0 : {
            items : 2,
          },
          640 : {
            items : 3,
            mouseDrag: true,
            touchDrag: true,
            pullDrag: true,
          },
        }
      })
    } else {
      weddingSlide.trigger('destroy.owl.carousel');
      weddingSlide.find('.owl-stage-outer').children(':eq(0)').unwrap();
    }
  }


  if ($('.minMaxExample').length) {
    $('.minMaxExample').datepicker({
      // Можно выбрать тольо даты, идущие за сегодняшним днем, включая сегодня
      minDate: new Date()
    })
  }

  $(window).scroll(handleAllEventsOnpageScroll);

  /**
   * Init owlCarousel for raiting section
   */
  initOwlCarousel();
  $(window).resize(initOwlCarousel);

  // const restaurantMenuOwl = $('#restaurant-menu-carousel');
  // restaurantMenuOwl.owlCarousel({
  //   items : 1,
  //   dots: true,
  //   nav: false,
  //   slideBy: 1,
  //   lazyLoad: true,
  //   // autoplay: true,
  //   loop: true,
  // })

  const menuBlock = $('.menu-block__slide');
  menuBlock.owlCarousel({
    items : 1,
    dots: true,
    nav: true,
    slideBy: 1,
    lazyLoad: true,
    loop: true,
  })

  const menuBlockTwo = $('.menu-block__slide-two.owl-carousel');
  menuBlockTwo.owlCarousel({
    items : 2,
    dots: true,
    nav: true,
    slideBy: 2,
    lazyLoad: true,
    dotsEach: true
  })

  menuBlockTwo.on('changed.owl.carousel', function(event) {
    $('.menu-block__slide-two.owl-carousel .owl-dot').hide()
    let active = $('.menu-block__slide-two.owl-carousel .owl-dot.active')
    active.show()

    active.next().fadeIn()
    active.prev().fadeIn()

    if (!active.prev().length) {
      active.next().next().fadeIn()
    }
    console.log(active.next());
    if (active.next().length === 1) {
      active.prev().prev().fadeIn()
    }
  })

  const banquetMenuOwl = $('#banquet-menu-carousel');
  banquetMenuOwl.owlCarousel({
    items : 1,
    dots: true,
    nav: false,
    slideBy: 1,
    lazyLoad: true,
    autoplay: true,
    loop: true
  })

  const storiesOwl = $('#stories');
  storiesOwl.owlCarousel({
    items : 8,
    margin: 33,
    dots: false,
    nav: false,
    slideBy: 1,
    lazyLoad: true,
    autoplay: false,
    loop: false,
    autoWidth: true
  })


  const banquetsSlider1Owl = $('#weddings-carousel');
  banquetsSlider1Owl.owlCarousel({
    items : 4,
    dots: true,
    nav: false,
    slideBy: 2,
    lazyLoad: true,
    autoplay: true,
    margin: 29,
    autoWidth: true,
  })

  const newYearOwl = $('.newYear-item .owl-carousel');
  newYearOwl.owlCarousel({
    items : 4,
    loop: true,
    dots: true,
    nav: false,
    slideBy: 2,
    lazyLoad: true,
    autoplay: true,
    margin: 29,
    autoWidth: true,
  })

  const jubileeSlider1Owl = $('#jubilee-carousel');
  jubileeSlider1Owl.owlCarousel({
    items : 4,
    dots: true,
    nav: false,
    slideBy: 2,
    lazyLoad: true,
    autoplay: true,
    margin: 29,
    autoWidth: true,
  })

  const corpSlider1Owl = $('#corp-carousel');
  corpSlider1Owl.owlCarousel({
    items : 4,
    dots: true,
    nav: false,
    slideBy: 2,
    lazyLoad: true,
    autoplay: true,
    margin: 29,
    autoWidth: true,
  })

  const servicesSlie = $('.services-block__slide')
  servicesSlie.owlCarousel({
    items : 2,
    dots: false,
    nav: false,
    loop: true,
    margin: 30,
    responsive: {
      0: {
        items : 1,
        margin: 15,
      },
      760: {
        items : 2,
        margin: 30,
      }
    }
  })

  let tabMenu = $('.menu-block__list a')
  tabMenu.click(function(e){
    let id = $(this).attr('data-tab')
    let active = $('.menu-block__list a.active')
    $(active.attr('data-tab')).removeClass('active')
    active.removeClass('active')
    $(this).addClass('active')
    $(id).addClass('active')
    e.preventDefault();
  })

  /**
   * Init animation for viewport screen
   */
  $(window).scroll(scrollAnimationHandler);

  /**
   * Инициализация селектов в форме
   */
  $('select').selectric();

  /**
   * Открытие модалки
   */
  $('[data-modal]').click(e => {
    e.originalEvent.preventDefault();
    if (e.currentTarget.dataset.modal) {
      openModal(e.currentTarget.dataset.modal);
    }
  });

  /**
   * Закрытие модалки
   */
  $('.modal').click(e => {
    if (e.target.classList.contains('modal__close-btn')) {
      return;
    }
    e.stopPropagation();
  })

  $('.modal-window').click(e => {
    e.currentTarget.classList.add('closing');
  })

  $('.modal-window').on('animationend', e => {
    if (e.originalEvent.animationName === 'fadeOut') {
      e.target.classList.remove('visible', 'closing');
      $('body').css('overflow', '');
    }
  })

  $('#menu').click(() => {
    $('#navigation').toggleClass('opened');
  })
  $('.navigation__menu a').click(() => {
    $('#navigation').removeClass('opened');
  })

  $('.navigation__menu-text').click((e) => {
    $('.navigation__menu-text').toggleClass('opened');
    // $('.navigation__menu-text').removeClass('opened');
    // e.target.classList.add('opened');
  })

  /**
   * Блок для показа, скрытия формы отзывов
   */

  $('#feedback-form-anchor').click((e) => {
    e.preventDefault();
    $('.fixed-feedback-form').addClass('visible');
  })

  $('.feedback-form').click(e => {
    if (e.target.classList.contains('modal__close-btn')) {
      return;
    }
    e.stopPropagation();
  })

  $('.fixed-feedback-form').click(e => {
    e.currentTarget.classList.add('closing');
  })

  $('.fixed-feedback-form').on('animationend', e => {
    if (e.originalEvent.animationName === 'fadeOut') {
      e.target.classList.remove('visible', 'closing');
      $('body').css('overflow', '');
    }
  })

  $('[name="phone"]').mask("+7 (999) 999-9999");

  $("a[href^='#']:not([href='#'])").click(function (event) {
    event.preventDefault();
    var id  = $(this).attr('href');
    var top = $(id).offset().top - 50;
    $('body,html').animate({scrollTop: top}, 1500);
  });

  /**
   * Init map
   */

  if ($('#main-map').length) {
    ymaps.ready(function(){
      // Указывается идентификатор HTML-элемента.
      var map = new ymaps.Map("main-map", {
          center: [52.261323, 104.288631],
          zoom: 12,
          controls: []
      });

      map.behaviors.disable(['drag', 'rightMouseButtonMagnifier', 'scrollZoom'])

      let newMap = document.querySelector('.newYear-map');
      if (newMap) {
        var miraj = new ymaps.Placemark(
          [52.230874, 104.314784],
          {
            // hintContent: '',
            hintContent: `
            <ul>
              <li>Ресторан "Мираж"</li>
              <li>Работаем
              Круглосуточно</li>
            </ul>
            `
          },
          {
            iconLayout: 'default#image',
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/newYear-page/mirage.svg',
            iconImageSize: [70, 26],
            iconImageOffset: [-35, -13]
          });

        var amstel = new ymaps.Placemark(
          [52.278734, 104.250288],
          {
            // hintContent: 'Ресторан "Amstel"',
            hintContent: `
            <ul>
              <li>Ресторан "Amstel"</li>
              <li>Пн-Чт 11:00 – 06:00</li>
              <li>Пт 11:00 – 7:00</li>
              <li>Сб 12:00 – 7:00</li>
              <li>Вс 12:00 – 6:00</li>
            </ul>
            `
          },
          {
            iconLayout: 'default#image',
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/newYear-page/Amstel.svg',
            iconImageSize: [96, 26],
            iconImageOffset: [-48, -13]
          });

        var mamini = new ymaps.Placemark(
          [52.255771, 104.311243],
          {
            // hintContent: '',
            hintContent: `
            <ul>
              <li>Ресторан "Mamini"</li>
              <li>Пн-Вс 12:00 – 24:00</li>
              <li>Пт-Сб 12:00 – 02:00</li>
            </ul>
            `
          },
          {
            iconLayout: 'default#image',
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/newYear-page/mamini-black.svg',
            iconImageSize: [28, 30],
            iconImageOffset: [14, -15]
          });

        var kiss = new ymaps.Placemark(
          [52.253805643918646,104.30779756671902],
          {
            // hintContent: '',
            hintContent: `
            <ul>
            <li>Ресторан "Kiss"</li>
              <li>Пн-Чт 12:00 - 02:00</li>
              <li>Пт-Вс 12:00 - 03:00</li>
            </ul>
            `
          },
          {
            iconLayout: 'default#image',
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/newYear-page/kiss-black.svg',
            iconImageSize: [32, 38],
            iconImageOffset: [-16, -19]
          });

      } else {
        var miraj = new ymaps.Placemark(
          [52.230874, 104.314784],
          {
            hintContent: 'Ресторан "Мираж"'
          },
          {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/map-marker-dark.svg',
            // Размеры метки.
            iconImageSize: [30, 42],
          });

        var amstel = new ymaps.Placemark(
          [52.278734, 104.250288],
          {
            hintContent: 'Ресторан "Amstel"',
          },
          {
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/map-marker-dark.svg',
            // Размеры метки.
            iconImageSize: [30, 42],
          });

        var mamini = new ymaps.Placemark(
          [52.255771, 104.311243],
          {
            hintContent: 'Ресторан "Mamini"',
          },
          {
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/map-marker-dark.svg',
            // Размеры метки.
            iconImageSize: [30, 42],
          });

        var kiss = new ymaps.Placemark(
          [52.255718, 104.311309],
          {
            hintContent: 'Ресторан "Kiss"',
          },
          {
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'sites/all/themes/arm/dest/assets/img/map-marker-dark.svg',
            // Размеры метки.
            iconImageSize: [30, 42],
          });
      }

      map.geoObjects
        .add(mamini)
        .add(amstel)
        .add(miraj)
        .add(kiss);
    });
  }

  $('.anketa-rating input').change(function(){
    let val = $(this).val()
    let stars = $(this).closest('.anketa-radio').find('label')
    stars.each(function(index, item){
      if (index < val) {
        $(item).addClass("active")
      } else {
        $(item).removeClass("active")
      }
    })
  })
  $('.navigation-phone__item').click(function(){
    $(this).parent().toggleClass('active')
  })
  $('.anketa-radio__select input').change(function(){
    let header = $(this).closest('.anketa-radio__select').prev()
    header.removeClass('active')
    header.text($(this).val())
  })
  $('.anketa-radio__header').click(function(){
    $(this).toggleClass('active')
  })
});

$(document).mouseup(function(e){
  let navigation = $('.navigation-phone')
  if (e.target!=navigation[0]&&navigation.has(e.target).length === 0){
    navigation.removeClass('active')
  }
})

function initOwlCarousel() {
  const owl = $('.owl-carousel-raiting');
  if ( $(window).width() < 1190 ) {
    owl.owlCarousel({
      loop:false,
      margin: 55,
      nav:false,
      dots: false,
      autoWidth: true,
      responsive : {
        0 : {
            center: true,
            loop: true,
        },
        640 : {
          center: false,
          loop: false,
        }
    }
    })
  } else {
    owl.addClass('off').trigger('destroy.owl.carousel');
    owl.find('.owl-stage-outer').children(':eq(0)').unwrap();
  }
}

let countUp = document.querySelectorAll('.countUp')

function countUpStart(){
  if (document.querySelector('.wedding-advantages.animated')) {
    countUp.forEach(function(item, index) {
      const options = {
        separator: '’'
      };
      let demo = new CountUp(item, 0, +item.textContent.split('’').join(''), 0, 3, options);
      if (!demo.error) {
        setTimeout(function(){
          demo.start();
        }, (index+1)*300)
      } else {
        console.error(demo.error);
      }
    })
  }
}

function scrollAnimationHandler(e) {
  const el = $("#animate-in-viewport");
  if (!el || !el[0]) return;
  var top_of_element = el.offset().top;
  var bottom_of_element = el.offset().top + el.outerHeight();
  var bottom_of_screen = e.currentTarget.innerHeight + e.currentTarget.scrollY;
  var top_of_screen = e.currentTarget.scrollY;

  if ((bottom_of_screen > top_of_element + 40) && (top_of_screen + 40 < bottom_of_element)){
    el.addClass('animated');
    countUpStart()
    $(window).off('scroll', scrollAnimationHandler)
  }
}

function openModal(modalName) {
  const el = $(`#modal-${modalName}`);
  if (!el || !el[0]) return;

  $('body').css('overflow', 'hidden');
  $(`#modal-${modalName}`).addClass('visible');
}

function handleAllEventsOnpageScroll(e) {
  handleFloatGalleryAnimation(e);
  handleARMbgText(e);
}

function prepareFloatGallery() {
  const el$ = $('.float-gallery');
  const firstLine$ = $('.float-gallery__row.row-1');
  const secondLine$ = $('.float-gallery__row.row-2');
  if (!el$ || !el$.length) return;

  const window$ = $(window);
  const windowWidth = window$.innerWidth();

  cloneGalleryPhotos(firstLine$, windowWidth);
  cloneGalleryPhotos(secondLine$, windowWidth);
}

function cloneGalleryPhotos(line$, windowWidth) {
  let lineWidth = line$.outerWidth();

  const nativeChildren = line$.children();
  const childrenCount = nativeChildren.length;

  let counter = 0;

  while (lineWidth < windowWidth * 2) {
    $(nativeChildren[counter]).clone().appendTo(line$);
    counter = (counter >= childrenCount) ? 0 : counter++;
    lineWidth = line$.outerWidth();
  }
}

function handleFloatGalleryAnimation(e) {
  const el = $('.float-gallery');
  const firstLine = $('.float-gallery__row.row-1');
  const secondLine = $('.float-gallery__row.row-2');
  if (!el || !el.length) return;

  const windowHeight = e.currentTarget.innerHeight;
  const elHeight = el.outerHeight();

  const top_of_screen = e.currentTarget.scrollY;
  const bottom_of_screen = windowHeight + top_of_screen;

  const top_of_element = el.offset().top;
  const bottom_of_element = el.offset().top + elHeight;
  const center_of_element = el.offset().top + elHeight / 2;


  let percent = 0;
  if ((top_of_screen < bottom_of_element) && (top_of_element < bottom_of_screen)) {
    if (top_of_screen + windowHeight / 2 < center_of_element) {
      percent = (center_of_element - top_of_screen) / windowHeight;
    } else if (top_of_screen + windowHeight / 2 > center_of_element) {
      percent = (center_of_element - top_of_screen) / windowHeight;
    } else {
      percent = (center_of_element - top_of_screen) / windowHeight;
    }
    percent = percent * 50 - 50;
  } else if (top_of_screen < center_of_element) {
    percent = 0;
  } else if (center_of_element < bottom_of_screen) {
    percent = -50;
  }

  if (firstLine && firstLine[0]) {
    firstLine.css('margin-left', `${percent - 15}%`);
  }
  if (secondLine && secondLine[0]) {
    secondLine.css('margin-left', `${-percent - 50 - 15}%`);
  }
}

function handleARMbgText(e) {
  const elements$ = $('.arm-text-bg');
  const windowHeight = e.currentTarget.innerHeight;
  const top_of_screen = e.currentTarget.scrollY;
  elements$.each( function() {
    const el$ = $(this);
    const elHeight = el$.outerHeight();
    if (top_of_screen < el$.offset().top + elHeight && top_of_screen + windowHeight > el$.offset().top) {
      let percent = (el$.offset().top + elHeight / 2 - top_of_screen) / windowHeight;
      percent = percent * 100 * 2 - 100;
      el$.css('transform', `translateX(${-percent}%)`);
    }
  })
}

let newYearLink = document.getElementById('newYear-link')
if (newYearLink) {
	timeDescr();
}
function timeDescr() {
	setTimeout(function(){
		newYearLink.classList.add('active')
		setTimeout(function(){
			newYearLink.classList.remove('active')
			timeDescr()
		}, 5000)
	}, 6000)
}

if (document.querySelector('.main-image-block') && document.querySelector('.main-image-block').getAttribute('data-video')) {
  document.querySelector('.main-image-block').append(videoElement())
  document.querySelector('.main-image-block').classList.add('video')
  document.querySelector('.main-image-block__mirror-line').append(videoElement())
}

function videoElement() {
  let video = document.createElement('video')
  video.src = document.querySelector('.main-image-block').getAttribute('data-video')
  video.autoplay = 'autoplay'
  video.preload = 'auto'
  video.muted = '0'
  video.loop="loop"
  video.poster = 'sites/all/themes/arm/dest/assets/img/wedding/main.jpg'
  // video.poster = 'sites/all/themes/arm/dest/assets/img/wedding/main.jpg'
  video.classList.add('main-video-bg')
  return video
}
